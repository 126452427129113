import React, { useState, useEffect, useRef } from 'react';

import './FindMore.scss';
import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import { ListArrow } from '../../Atoms/Icons/Icons';

let classNames = require('classnames');

const FindMore = ({ componentData, alignment }) => {
  const intl = useIntl();
  return (
    <div
      className={`findMoreBlock ${
        alignment === 'right' ? 'alignLeft' : 'alignRight'
      }`}
    >
      <div className="findMore">
        <div className="titleDesktop">
          {intl.formatMessage({
            id: 'detailsActivities.findMore',
          })}
        </div>
        <ul>
          {componentData.map((findMore, i) => (
            <li key={i}>
              <a target="_blank" href={findMore?.field_link?.url}>
                <div className="listTitle">
                  <ListArrow color="color_yellow" aria-hidden="true" />
                  <h4>{findMore?.field_subtitle}</h4>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: findMore?.field_text?.processed,
                  }}
                />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FindMore;
